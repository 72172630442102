<template>
  <div>
    <div class='mask' v-if='videoState == true' @click='masksCloseFun'></div>
    <div class="videomasks" v-if="videoState == true">
      <div class="closeBtn" @click='masksCloseFun'>
        <i class="el-icon-close"></i>
      </div>
      <video :src='videoSrc' controls='controls' autoplay loop class="video">
      </video>
    </div>
  </div>
</template>

<script>
export default {
  name: "videoPlayer",
  props: {
    videoSrc: {
      type: String,
      default: ''
    }

  },
  data() {
    return {
    };
  },
  computed: {
    videoState() {
      return this.$store.state.account.videoState
    }
  },
  methods: {
    masksCloseFun() {
      this.$store.commit("TO_VIDEOCLOSE");
    }
  }
};
</script>


<style lang="less" scoped>
.mask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: #000000;
  opacity: 0.6;
}

.videomasks {
  width: 95%;
  height: 95%;
  max-width: 1200px;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 20;
  transform: translate(-50%, -50%);
}

.closeBtn {
  width: 80%;
  height: 25px;
  margin: 0px auto;
  border: 1px solid #010001;
  background-color: #010001;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  /* background: red; */
  /* background: white; */
  .el-icon-close {
    color: #fff;
    padding-top: 8px;
    cursor: pointer;
    width: 25px;
    height: 25px;
  }
}

.videomasks video {
  // width: 80%;
  // height: 90%;
  width: 100%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.video {
  width: 100%;
}
</style>
