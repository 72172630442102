<template>
  <div>
    <div v-if="!isMobile" class="pc">
      <div class="joinBanner">
        <div>
          <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/firstBanner.png" />
        </div>
        <!-- <div>
          <p>行业格局已经发生巨变</p>
          <p>我们为您提供场景化的</p>
          <p>深度行业解决方案</p>
        </div> -->
        <div>
          <p>活好 · 皮实</p>
          <p>合群 · 上进</p>
        </div>
      </div>
      <div class="top_box">
        <div class="pc_change">
          <div>
            <a @click.prevent="recruitBtnDidiClick(1)">社会招聘</a>
            <a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
            <a class="school_recruit" @click.prevent="recruitBtnDidiClick('second')">校园招聘</a>
            <a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
            <a class="school_recruit" @click.prevent="goToExam()">在线考试</a>
          </div>
        </div>
      </div>
      <div class="recruitment">
        <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/recruitmenBack.png" />

        <div class="recruitmentTitle_box">
          <p class="recruitmentTitle">加入我们</p>
          <div class="recruitmentPosition">
            <div class="recruitmentCategory">
              <div class="itemRest" v-for="(item, index) in recruitData" :key="index"
                @click="recruitBtnDidiClick(index + 1)">
                <div class="itemTitle">
                  <p>
                    {{ item.title }}
                  </p>
                </div>
                <div class="itemImg">
                  <img :src="item.imgUrl" />
                </div>

                <div class="itemSpan">
                  <span>{{ item.desc }}</span>
                  <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/point1.png" style="width: 5%; margin-left: 30px" />
                </div>
              </div>
            </div>
          </div>
          <div class="email">
            <p>简历也可直接发送以下邮箱：<a>zhaopzx@i2finance.net</a></p>
            <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/pint2.png" />
          </div>
        </div>
      </div>
      <div class="us">
        <p>走进我们</p>
        <p class="us_content" style="text-align: left">
          成熟的产品+先进的软件工程实施方法+技艺精湛的技术团队是艾融软件满足客户需求的三大法宝。艾融软件的技术团队是一支朝气蓬勃、激情四射的专业团队，活好、皮实、合群、上进，是艾融软件技术团队的真实写照。
        </p>
        <div class="secContent">
          <div class="secTopRow">
            <div>
              <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/work.png" style="width: 100%" />
            </div>
            <div>
              <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/workLog.png" style="width: 30%" />
              <span>我们的办公区</span>
            </div>
            <div>
              <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/meeting.png" style="width: 100%" />
            </div>
            <div>
              <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/meetingLog.png" style="width: 30%" />
              <span>我们的会议厅</span>
            </div>
            <div>
              <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/cultivateLog.png" style="width: 30%" />
              <span>培训基地</span>
            </div>
            <div>
              <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/cultivate.png" style="width: 100%" />
            </div>
            <div>
              <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/restLog.png" style="width: 30%" />
              <span>我们的休息区</span>
            </div>
            <div>
              <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/rest.png" style="width: 100%" />
            </div>
          </div>
        </div>
      </div>
      <div class="employee">
        <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/employeeBackgorund.png" />
        <p class="employeeTitle">员工心声</p>
        <div class="employeePosition">
          <div class="employeeVideo">
            <div class="employeeVideo_item" @mouseover="changeActive()" @mouseleave="mouseLeave()">
              <div id="word1" class="changeVideo">
                <span>艾融的TA</span><span>（项目采访）</span>
                <p>
                  加入艾融的那天起，就走上了收获的旅程。收获友谊，收获成长，收获经验，收获未来。
                </p>
              </div>
              <div>
                <div id="mask"></div>
                <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/employee1.png" id="employee_heart_voice_bg1" />
                <!--                    <video src="https://img.i2soft.net/1634263555513729.mp4" controls="controls"></video>-->
                <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/employee3.png" style="
                    display: none;
                    width: 50px;
                    height: 50px;
                    position: absolute;
                    z-index: 19;
                    top: 50%;
                    left: 45%;
                  " id="img1" @click="
                    videoBtnDidClick(
                      'https://img.i2soft.net/1634263555513729.mp4'
                    )
                    " />
              </div>
              <!-- width: 50px;
              height: 50px; */ -->
            </div>
            <div class="employeeVideo_item" @mouseover="changeActive2()" @mouseleave="mouseLeave2()">
              <div id="word2" class="changeVideo">
                <span>艾融的活法</span><span>（企业文化）</span>
                <p>
                  永远年轻，永远热泪盈眶。尽情挥洒自己的才情，除了工作，我们还有精彩的生活。
                </p>
              </div>
              <div>
                <div id="mask2"></div>
                <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/employee2.png" id="employee_heart_voice_bg2" />
                <!--                    <video src="https://img.i2soft.net/1634263536049409.mp4" controls="controls"></video>-->
                <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/employee3.png" style="
                    display: none;
                    width: 50px;
                    height: 50px;
                    position: absolute;
                    z-index: 19;
                    top: 50%;
                    left: 45%;
                  " id="img2" @click="
                    videoBtnDidClick(
                      'https://img.i2soft.net/1634263536049409.mp4'
                    )
                    " />
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="rest">
        <p class="restTitle">培训成长体系</p>
        <div class="titleTop">
          <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/birdLog.png" />
          <p class="titleTopP">笨鸟学院技术论坛</p>
        </div>
        <div class="restVideo">
          <div class="restVideo_item">
            <div class="position">
              <div class="birdImgBox" @mouseover="changeActive3()" @mouseleave="mouseLeave3()">
                <div id="mask3"></div>
                <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/bird.png" class="birdImg" id="b2" />
                <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/employee3.png" @click="
                  videoBtnDidClick(
                    'https://img.i2soft.net/1634263569265467.mp4'
                  )
                  " style="
                    display: none;
                    width: 50px;
                    height: 50px;
                    position: absolute;
                    z-index: 18;
                    top: 50%;
                    padding-left: 1.5%;
                  " id="img3" />
              </div>

              <div class="backColor"></div>
              <div class="backBorder"></div>
            </div>
          </div>
          <div class="restVideo_item">
            <p style="
                text-align: left;
                padding-top: 50px;
                font-size: 16px;
                margin-left: 15px;
                width: 70%;
              ">
              无论你是初入职场的新人，还是奋斗路上的中流砥柱，我们都准备了丰富的培训课程，助你攀上新的高峰。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="mobile">
      <div class="banner">
        <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/mobile/banner.png" />
      </div>
      <div class="recruitmentMobile">
        <p class="recruitmentTitleMobile">招聘岗位</p>
        <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/mobile/recruitment.png" />
        <div class="recruitmentTitleMobile_box">
          <div class="changeMobile">
            <div style="padding-right: 8px">
              <a @click.prevent="recruitBtnDidiClick(1)">社会招聘</a>
              <a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
              <a @click.prevent="recruitBtnDidiClick('second')">校园招聘</a>
            </div>
          </div>
          <div class="recruitmentCategoryMobile">
            <div class="itemRestMobile" v-for="(item, index) in m_recruitData" :key="index + 20"
              @click="recruitBtnDidiClick(index + 1)">
              <div class="itemImgMobile">
                <img :src="item.imgUrl" />
              </div>
              <div class="itemTitleMobile">
                <p>{{ item.title }}</p>
              </div>
              <div class="itemSpanMobile">
                <span>{{ item.desc }}</span>
                <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/mobile/point.png" style="width: 5%" />
              </div>
            </div>
          </div>
        </div>
        <div class="emailMobile">
          <p>简历也可直接发送以下邮箱：<a>zhaopzx@i2finance.net</a></p>
        </div>
      </div>
      <div class="usMobile">
        <p>走进我们</p>
        <p style="text-align: left; padding: 5px">
          成熟的产品+先进的软件工程实施方法+技艺精湛的技术团队是艾融软件满足客户需求的三大法宝。艾融软件的技术团队是一支朝气蓬勃、激情四射的专业团队，活好、皮实、合群、上进，是艾融软件技术团队的真实写照。
        </p>
        <div class="secContentMobile">
          <div class="secTopRowMobile">
            <div>
              <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/work.png" style="width: 100%" />
            </div>
            <div>
              <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/workLog.png" style="width: 39%" />
              <span>我们的办公区</span>
            </div>
            <div>
              <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/meeting.png" style="width: 100%" />
            </div>
            <div>
              <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/meetingLog.png" style="width: 39%" />
              <span>我们的会议厅</span>
            </div>
            <div>
              <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/cultivateLog.png" style="width: 39%" />
              <span>培训基地</span>
            </div>
            <div>
              <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/cultivate.png" style="width: 100%" />
            </div>
            <div>
              <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/restLog.png" style="width: 39%" />
              <span>我们的休息区</span>
            </div>
            <div>
              <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/rest.png" style="width: 100%" />
            </div>
          </div>
        </div>
      </div>
      <div class="employee">
        <p class="employeeTitleMobile">员工心声</p>
        <div class="employeePositionMobile">
          <div class="employeeVideoMobile">
            <div class="employeeVideoMobile_item">
              <div>
                <span>艾融的TA</span><span>（项目采访）</span>
                <p>
                  加入艾融的那天起，就走上了收获的旅程。收获友谊，收获成长，收获经验，收获未来。
                </p>
              </div>
              <div>
                <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/mobile/video2.png" />
                <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/employee3.png" style="
                    width: 36px;
                    height: 36px;
                    position: absolute;
                    z-index: 1;
                    top: 60%;
                    left: 45%;
                  " @click="
                    videoBtnDidClick(
                      'https://img.i2soft.net/1634263555513729.mp4'
                    )
                    " />
              </div>
            </div>
            <div class="employeeVideoMobile_item">
              <div>
                <span>艾融的活法</span><span>（企业文化）</span>
                <p>
                  永远年轻，永远热泪盈眶。尽情挥洒自己的才情，除了工作，我们还有精彩的生活。
                </p>
              </div>
              <div>
                <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/mobile/video1.png" />
                <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/employee3.png" style="
                    width: 36px;
                    height: 36px;
                    position: absolute;
                    z-index: 1;
                    top: 60%;
                    left: 45%;
                  " @click="
                    videoBtnDidClick(
                      'https://img.i2soft.net/1634263536049409.mp4'
                    )
                    " />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="restMobile">
        <p class="restTitleMobile">培训成长体系</p>
        <div class="titleTopMobile">
          <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/birdLog.png" />
          <p class="titleTopPMobile">笨鸟学院技术论坛</p>
        </div>
        <div class="restVideoMobile">
          <div class="restVideoMobile_item">
            <div class="positionMobile">
              <div>
                <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/mobile/video3.png" class="birdImgMobile" />
                <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/employee3.png" @click="
                  videoBtnDidClick(
                    'https://img.i2soft.net/1634263569265467.mp4'
                  )
                  " style="
                    width: 23px;
                    height: 23px;
                    position: absolute;
                    z-index: 4;
                    top: 50%;
                    left: 35%;
                  " />
              </div>
              <div class="backColorMobile"></div>
              <div class="backBorderMobile"></div>
            </div>
          </div>
          <div style="
              text-align: left;
              padding-top: 75px;
              margin-left: 21px;
              font-size: 13px;
              line-height: 20px;
            " class="restVideoMobile_item">
            无论你是初入职场的新人，还是奋斗路上的中流砥柱，我们都准备了丰富的培训课程，助你攀上新的高峰。
          </div>
        </div>
      </div>
    </div>
    <video-player :videoSrc="videoUrl" />
  </div>
</template>
<script>
import { isMobile } from "../../utils/index";
import videoPlayer from "../video/index";
export default {
  components: { videoPlayer },
  data() {
    return {
      isMobile: false,
      videoState: this.$store.state.account.videoState,
      videoUrl: "",
      version: "",
      recruitData: [
        {
          title: "用户体验设计类",
          imgUrl: "https://img.i2soft.net/i2official-web/assets/images/joinus/item1.png",
          desc: "查看岗位",
        },
        {
          title: "技术研发类",
          imgUrl: "https://img.i2soft.net/i2official-web/assets/images/joinus/item2.png",
          desc: "查看岗位",
        },
        {
          title: "商务拓展类",
          imgUrl: "https://img.i2soft.net/i2official-web/assets/images/joinus/item3.png",
          desc: "查看岗位",
        },
        {
          title: "人力资源类",
          imgUrl: "https://img.i2soft.net/i2official-web/assets/images/joinus/item4.png",
          desc: "查看岗位",
        },
      ],
      m_recruitData: [
        {
          title: "用户体验设计类",
          imgUrl: "https://img.i2soft.net/i2official-web/assets/images/joinus/mobile/item3.png",
          desc: "查看岗位",
        },
        {
          title: "技术研发类",
          imgUrl: "https://img.i2soft.net/i2official-web/assets/images/joinus/mobile/item1.png",
          desc: "查看岗位",
        },
        {
          title: "商务拓展类",
          imgUrl: "https://img.i2soft.net/i2official-web/assets/images/joinus/mobile/item4.png",
          desc: "查看岗位",
        },
        {
          title: "人力资源类",
          imgUrl: "https://img.i2soft.net/i2official-web/assets/images/joinus/mobile/item2.png",
          desc: "查看岗位",
        },
      ],
    };
  },
  created() { },
  mounted() {
    this.isMobile = isMobile();
    window.addEventListener("scroll", this.getScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.getScroll);
  },
  methods: {
    videoBtnDidClick(url) {
      this.videoUrl = url;
      this.$store.commit("TO_VIDEOS");
    },
    mouseLeave() {
      // eslint-disable-next-line no-unused-vars
      let mask = document.getElementById("mask");
      let word = document.getElementById("word1");
      let img = document.getElementById("img1");
      word.className = "changeVideo";
      mask.className = "mouseLeave";
      img.style.display = "none";
    },
    changeActive() {
      let mask = document.getElementById("mask");
      let word = document.getElementById("word1");
      let img = document.getElementById("img1");
      let bg1 = document.getElementById('employee_heart_voice_bg1')
      mask.className = "activeVideo";
      mask.style.height = bg1.height + 'px'
      mask.style.width = bg1.width + 'px'
      img.style.display = "block";
      word.className = "changeVideoLeave";
    },
    mouseLeave2() {
      // eslint-disable-next-line no-unused-vars
      let mask = document.getElementById("mask2");
      let word = document.getElementById("word2");
      let img = document.getElementById("img2");
      mask.className = "mouseLeave";
      img.style.display = "none";
      word.className = "changeVideo";
    },
    changeActive2() {
      let mask = document.getElementById("mask2");
      let word = document.getElementById("word2");
      let img = document.getElementById("img2");
      mask.className = "activeVideo";
      mask.className = "activeVideo";
      let bg1 = document.getElementById('employee_heart_voice_bg2')
      mask.style.height = bg1.height + 'px'
      mask.style.width = bg1.width + 'px'
      img.style.display = "block";
      word.className = "changeVideoLeave";
    },
    mouseLeave3() {
      let mask = document.getElementById("mask3");
      let img = document.getElementById("img3");
      mask.className = "mouseLeave";
      img.style.display = "none";
    },
    changeActive3() {
      let mask = document.getElementById("mask3");
      let img = document.getElementById("img3");
      let b2 = document.getElementById("b2");
      mask.className = "activeVideo";
      mask.style.width = b2.width + "px";
      mask.style.height = b2.height + "px";
      img.style.display = "block";
      img.style.left = b2.offsetLeft + b2.width / 2 - img.width / 2 + "px";
    },
    getScroll() {
      // this.indexNumTop = $("#index_num").offset().top;
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (
        !!document.documentElement.scrollTop &&
        document.documentElement.scrollTop >= 70
      ) {
        //设置滚动大于300时的执行内容
        // console.log(
        //   "document.documentElement.scrollTop=",
        //   document.documentElement.scrollTop
        // );
        this.$store.commit("TO_AfterScroll");
      } else {
        this.$store.commit("TO_AfterScroll_false");
      }
    },
    // 跳转招聘页面
    recruitBtnDidiClick(type) {
      if (typeof type === "string" && type !== "second") {
        return;
      }
      if (typeof type === "string" && type === "second") {
        type = 99;
      }
      this.$router.push({ path: "/recruit", query: { category: type } });
      this.$router.afterEach(() => {
        window.scrollTo(0, 0);
      });
    },

    // 跳转招聘考试页面
    goToExam() {
      this.$router.push({ path: "/examList" });
    },
  },
};
</script>
<style scoped>
.recruitmentTitle_box {
  position: absolute;
  top: 0%;
  text-align: right;
  width: 80%;
  height: 100%;
  left: 3%;
}

.changeVideoLeave {
  background-color: #245da1;
  color: #ffffff;
}

.changeVideo {
  background-color: #ffffff;
  color: #333333;
}

.activeVideo {
  background-color: #000000;
  opacity: 0.3;
}

.mouseLeave {
  display: block;
}

#mask {
  position: absolute;
}

#mask2 {
  position: absolute;
}

#mask3 {
  position: absolute;
  top: 75px;
  right: 15%;
  z-index: 17;
}

/* .pc_change div {
  margin-right: 10px;
  position: relative;
  margin-top: 6%;
  cursor: default;
} */
.top_box {
  position: relative;
  padding: 1px 0px;
  height: 60px;
  top: -3px;
  background-color: #f6f6f6;

  .pc_change {
    position: absolute;
    left: 60%;
    z-index: 1;
  }

  .pc_change div {
    margin-right: 10px;
    position: relative;
    margin-top: 6%;
    cursor: default;

    .active {
      padding-bottom: 5px;
      border-bottom: 2px solid #265ea2;
      color: #265ea2;
    }
  }

  .pc_change a:nth-child(2n-1) {
    text-decoration: none;
    color: #000000;
    font-size: 15px;
    cursor: pointer;
  }

  .pc_change a:nth-child(2) {
    font-size: 15px;
  }

  .pc_change a:nth-child(2n-1):hover {
    padding-bottom: 5px;
    border-bottom: 2px solid #265ea2;
    color: #265ea2;
  }

  .pc_change a:nth-child(2n-1):visited {
    padding-bottom: 5px;
    border-bottom: 2px solid #265ea2;
    color: #265ea2;
  }
}



/* .pc_change a:nth-child(2n-1) {
  text-decoration: none;
  color: #000000;
  font-weight: bold;
  font-family: PingFangSC-Medium;
  border-bottom: 2px solid #d5d5d5;
  padding-bottom: 8px;
  font-size: 20px;
  cursor: pointer;
}
.pc_change a:nth-child(2n) {
  font-family: PingFangSC-Medium;
  border-bottom: 2px solid #d5d5d5;
  padding-bottom: 8px;
  font-size: 20px;
}
.pc_change a:nth-child(2n-1):hover {
  color: #265ea2;
  border-bottom: 2px solid #868788;
  padding-bottom: 8px;
}
.pc_change a:nth-child(2n-1):visited {
  color: #265ea2;
  border-bottom: 2px solid #868788;
  padding-bottom: 8px;
} */
.email p {
  margin: 0;
  font-family: PingFangSC-Regular;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: left;
}

.email p a {
  color: #245da1;
}

.email img {
  position: absolute;
  top: 0;
  left: 78.5%;
  height: 100%;
}

.email {
  position: absolute;
  top: 90%;
  left: 19%;
  width: 100%;
}

.itemRest:hover {
  filter: progid:DXImageTransform.Microsoft.Shadow(color=#909090, direction=120, strength=4);
  -moz-box-shadow: 2px 2px 5px #909090;
  -webkit-box-shadow: 2px 2px 5px #909090;
  box-shadow: 2px 2px 5px #909090;
  transform: translateY(-15px);
}

.itemSpan {
  position: absolute;
  top: 25%;
  font-size: 16px;
  color: #245da1;
  font-family: PingFangSC-Regular;
}

.recruitmentPosition {
  position: absolute;
  z-index: 10;
  top: 28%;
  left: 18%;
}

.itemTitle {
  position: absolute;
}

.itemRest p {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1px;
  margin-top: 20px;
  font-family: PingFangSC-Semibold;
  margin-left: 20px;
  /* background: red;  */
  width: 100%;
  text-align: left;
}

.itemRest {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-right: 10px;
  margin-left: 10px;
}

.recruitmentCategory {
  display: flex;
}

.recruitmentWord div {
  flex: 0 0 20%;
  display: flex;
}

.recruitment img:nth-child(1) {
  width: 100%;
  height: 100%;
}

.recruitmentTitle {
  padding: 0;
  font-size: 30px;
  position: absolute;
  z-index: 10;
  top: 20px;
  left: 18%;
  margin-left: 10px;
}

.recruitment {
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
}

.titleTop img {
  width: 16%;
  height: 60%;
}

.titleTopP {
  padding: 0;
  margin-left: 20px;
  font-weight: bold;
  letter-spacing: 3px;
  font-size: 22px;
}

.titleTop {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 19;
  width: 320px;
  height: 50px;
  background-color: #ffffff;
  filter: progid:DXImageTransform.Microsoft.Shadow(color=#909090, direction=120, strength=4);
  -moz-box-shadow: 2px 2px 5px #909090;
  -webkit-box-shadow: 2px 2px 5px #909090;
  box-shadow: 2px 2px 5px #909090;
  left: 42%;
  top: 40%;
}

.backBorder {
  width: 70%;
  height: 70%;
  border: 2px #979797 solid;
  top: 30px;
  right: 1%;
  position: absolute;
}

.backColor {
  background-color: #438fc7;
  width: 70%;
  height: 70%;
  position: absolute;
  top: 105px;
  right: 10%;
  z-index: 10;
}

.birdImg {
  height: 70%;
  position: absolute;
  z-index: 15;
  top: 75px;
  right: 15%;
  width: 70%;
}

.position {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.restVideo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
}

.restVideo_item {
  display: flex;
  flex: 0 0 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rest {
  margin-top: 50px;
  height: 500px;
  position: relative;
  margin-bottom: 60px;
}

.restTitle {
  margin: 0;
  padding: 0;
  font-size: 30px;
  margin-bottom: 25px;
}

.employee {
  overflow: hidden;
  position: relative;
}

.employeeVideo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  margin-top: 5%;
}

.employeeVideo_item div:nth-child(2) {
  width: 100%;
  overflow: hidden;
  position: relative;
}

#word1,
#word2 {
  text-align: left;
  padding: 20px 20px 30px 20px;
}

.employeeVideo_item div:nth-child(1) p {
  margin: 0;
  padding: 0;
  font-family: PingFangSC-Semibold;
  font-size: 16px;
}

.employeeVideo_item div:nth-child(1) span {
  margin: 0;
  padding: 0;
  font-family: PingFangSC-Semibold;
  font-size: 16px;
}

.employeeVideo_item div:nth-child(1) span:nth-child(1) {
  margin: 0;
  padding: 0;
  font-family: PingFangSC-Semibold;
  font-weight: bold;
  font-size: 20px;
}

.employeeVideo_item {
  flex: 0 0 29%;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  overflow: hidden;
  position: relative;
}

.employeePosition {
  top: 100px;
  left: 0px;
  width: 100%;
  z-index: 10;
  position: absolute;
  text-align: center;
}

.employee img {
  width: 100%;
  height: 100%;
  z-index: 11;
}

.employeeTitle {
  width: 100%;
  text-align: center;
  font-size: 30px;
  position: absolute;
  z-index: 10;
  left: 0;
  top: -10px;
  margin-top: 50px;
}

.secContent {
  width: 60%;
  margin: auto;
  margin-bottom: 60px;
}

.secTopRow {
  flex-wrap: wrap;
  display: flex;
}

.secTopRow div span {
  font-family: PingFangSC-Semibold;
  padding-top: 5px;
  font-size: 16px;
}

.secTopRow div {
  flex: 0 0 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.us p:nth-child(1) {
  margin: auto;
  font-family: PingFangSC-Light;
  font-size: 30px;
  color: rgba(51, 51, 51, 100);
  padding-top: 25px;
}

.us p:nth-child(2) {
  margin: auto;
  width: 50%;
  font-family: PingFangSC-Light;
  font-size: 16px;
  color: rgba(51, 51, 51, 100);
  padding-top: 54px;
  padding-bottom: 50px;
  text-align: left;
}

.joinBanner {
  overflow: hidden;
  position: relative;
}

.joinBanner p {
  padding: 0;
  margin: 0;
  color: rgba(255, 255, 255, 100);
  font-size: 40px;
  text-align: left;
  font-weight: bold;
  font-family: PingFangSC-Medium;
}

.joinBanner img {
  width: 100%;
  height: 100%;
}

.joinBanner div:nth-child(2) {
  position: absolute;
  z-index: 10;
  top: 150px;
  right: 10%;
}

@media screen and (max-width: 479px) {
  .banner img {
    width: 100%;
    height: inherit;
  }

  .usMobile p:nth-child(2) {
    margin: auto;
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: rgba(51, 51, 51, 100);
    padding-top: 22px;
    padding-bottom: 17px;
    line-height: 20px;
  }

  .secContentMobile {
    margin: auto;
    margin-bottom: 35px;
    width: 93%;
  }

  .usMobile p:nth-child(1) {
    margin: auto;
    font-family: PingFangSC-Semibold;
    font-size: 16px;
    color: rgba(51, 51, 51, 100);
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .secTopRowMobile div span {
    font-family: PingFangSC-Regular;
    padding-top: 6px;
    font-size: 10px;
  }

  .secTopRowMobile div {
    flex: 0 0 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .secTopRowMobile {
    flex-wrap: wrap;
    display: flex;
  }

  .employeePositionMobile {
    top: 100px;
    left: 0px;
    width: 100%;
    z-index: 10;
  }

  .employeeVideoMobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    margin-top: 5%;
  }

  .employeeVideoMobile_item div:nth-child(2) {
    width: 100%;
  }

  .employeeVideoMobile_item div:nth-child(1) {
    text-align: left;
    padding: 12px 11px 16px 16px;
    background-color: #f6f6f6;
  }

  .employeeVideoMobile_item div:nth-child(1) p {
    margin: 0;
    padding: 0;
    font-family: PingFangSC-Semibold;
    font-size: 12px;
    line-height: 19px;
  }

  .employeeVideoMobile_item div:nth-child(1) span {
    margin: 0;
    padding: 0;
    font-family: PingFangSC-Semibold;
    font-size: 12px;
  }

  .employeeVideoMobile_item div:nth-child(1) span:nth-child(1) {
    margin: 0;
    padding: 0;
    font-family: PingFangSC-Semibold;
    font-weight: bold;
    font-size: 16px;
  }

  .employeeVideoMobile_item {
    flex: 0 0 29%;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    overflow: hidden;
    position: relative;
    margin-bottom: 19px;
    border-radius: 5px;
  }

  .employeeTitleMobile {
    font-size: 16px;
    font-family: PingFangSC-Semibold;
    margin-bottom: 24px;
    margin-top: 0px;
  }

  .restTitleMobile {
    font-family: PingFangSC-Semibold;
    font-size: 16px;
    margin: 0;
  }

  .restMobile {
    margin-top: 17px;
    position: relative;
  }

  .restVideoMobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 200px;
  }

  .restVideoMobile_item {
    display: flex;
    flex: 0 0 45%;
    flex-direction: column;
    justify-content: center;
  }

  .positionMobile {
    position: relative;
    height: 100%;
  }

  .backColorMobile {
    background-color: #245da1;
    width: 85%;
    height: 127px;
    position: absolute;
    top: 58px;
    left: 13px;
    z-index: 2;
  }

  .backBorderMobile {
    width: 152px;
    height: 127px;
    border: 2px #979797 solid;
    top: 30px;
    left: 17px;
    position: absolute;
  }

  .birdImgMobile {
    height: 127px;
    width: 85%;
    z-index: 3;
    top: 38px;
    position: absolute;
    left: 0px;
  }

  .titleTopMobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 4;
    width: 160px;
    height: 23px;
    background-color: #ffffff;
    filter: progid:DXImageTransform.Microsoft.Shadow(color=#909090, direction=120, strength=4);
    -moz-box-shadow: 2px 2px 5px #909090;
    -webkit-box-shadow: 2px 2px 5px #909090;
    box-shadow: 2px 2px 5px #909090;
    left: 30%;
    top: 30%;
    position: absolute;
  }

  .titleTopPMobile {
    padding: 0;
    margin-left: 11px;
    font-weight: bold;
    letter-spacing: 2px;
    font-size: 12px;
  }

  .titleTopMobile img {
    width: 13%;
    height: 60%;
  }

  .recruitmentMobile {
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
  }

  .recruitmentMobile img {
    width: 100%;
    height: 100%;
  }

  .recruitmentTitleMobile {
    margin-bottom: 27px;
    margin-top: 18px;
  }

  .changeMobile a:nth-child(2n-1) {
    text-decoration: none;
    color: #000000;
    font-family: PingFangSC-Medium;
    font-size: 12px;
  }

  .changeMobile a:nth-child(2) {
    font-family: PingFangSC-Medium;
    font-size: 12px;
  }

  .changeMobile a:nth-child(2n-1):hover {
    color: #6dbdb6;
  }

  .changeMobile a:nth-child(2n-1):visited {
    color: #6dbdb6;
  }

  .recruitmentTitleMobile_box {
    position: absolute;
    top: 15%;
    text-align: right;
    width: 95%;
    height: 80%;
    padding-left: 10px;
    padding-top: 10px;
  }

  .recruitmentCategoryMobile {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    margin-top: 16px;
  }

  .itemRestMobile {
    display: flex;
    flex: 0 0 47%;
    margin: 5px;
    border-radius: 5px;
  }

  .itemSpanMobile {
    position: absolute;
    font-size: 10px;
    margin-top: 44px;
    text-align: left;
    margin-left: 20px;
  }

  .itemTitleMobile {
    position: absolute;
  }

  .itemTitleMobile p {
    font-family: PingFangSC-Semibold;
    margin: 0;
    font-size: 12px;
    margin-top: 22px;
    margin-bottom: 5px;
    margin-left: 20px;
  }

  .itemSpanMobile img {
    margin-left: 5px;
  }

  .emailMobile {
    position: absolute;
    top: 93%;
    width: 100%;
  }

  .emailMobile p {
    margin: 0;
    font-family: PingFangSC-Regular;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: left;
    text-align: center;
  }

  .emailMobile p a {
    color: #245da1;
  }
}
</style>
